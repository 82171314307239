
import {
  onUnmounted,
  onMounted,
  defineComponent,
  inject,
  reactive,
  ref,
  watch,
} from "vue";
import { PageParams } from "@/apis/interface/PageParams";
import { RespBody, RespBodyPager } from "@/apis/interface/Resp";
import {
  IFormServiceJobInvite,
  FormServiceJobInviteFilter,
  FormServiceJobInviteModel,
} from "@/apis/interface/IFormServiceJobInvite";
import bus from "@/utils/bus";
import { ElMessage } from "element-plus";

export default defineComponent({
  name: "List",
  components: {},
  setup() {
    const apiFormServiceJobInvite = inject(
      "apiFormServiceJobInvite"
    ) as IFormServiceJobInvite;

    const pageParams: PageParams = reactive({
      aaIndex: 1,
      aaSize: 50,
    });

    const filter: FormServiceJobInviteFilter = reactive({
      senderUserId: null,
      serviceBeanId: null,
      serviceKey: null,
      serviceId: null,
      companyName: null,
      phone: null,
      email: null,
      requirement: null,
    });

    let clearFilter = () => {
      filter.senderUserId = null;
      filter.serviceBeanId = null;
      filter.serviceKey = "";
      filter.serviceId = null;
      filter.companyName = null;
      filter.phone = null;
      filter.email = null;
      filter.requirement = null;
      pageParams.aaIndex = 1;
      loadData();
    };

    let dataList = ref<FormServiceJobInviteModel[]>([]);
    let loading = ref(false);

    onUnmounted(() => {
      bus.off("on-table-row-edit-ok");
    });

    let loadData = async () => {
      loading.value = true;
      dataList.value = [];
      const resp = await apiFormServiceJobInvite.getList(pageParams, filter);
      const pager = resp as RespBodyPager<FormServiceJobInviteModel>;
      pageParams.aaSize = pager.object.pageSize;
      pageParams.aaIndex = pager.object.pageCurrent;
      pageParams.total = pager.object.totalCount;
      dataList.value = pager.object.listData;
      // console.log("==>", dataList);
      loading.value = false;
    };

    let clickCreate = () => {
      bus.emit("on-table-row-edit", {
        dataList: [],
        dataRow: new FormServiceJobInviteModel({}),
      });
    };

    let statusFormat = (status: number) => {
      return status == 0 ? "待处理" : "已处理";
    };

    onMounted(() => {
      loadData();
      bus.on("on-table-row-edit-ok", () => {
        loadData();
      });
    });

    let deleteRow = async (id: number) => {
      let result = (await apiFormServiceJobInvite.remove([
        id,
      ])) as RespBody<number>;
      if (result.object > 0) {
        ElMessage({
          type: "success",
          message: "刪除成功！",
        });
        await loadData();
      } else
        ElMessage({
          type: "error",
          message: "刪除失敗",
        });
    };

    watch(
      () => pageParams.aaIndex,
      () => {
        loadData();
      }
    );
    watch(
      () => pageParams.aaSize,
      () => {
        pageParams.aaIndex = 1;
        loadData();
      }
    );

    let tableHeight = ref(0);

    let downloadExcel = () => {
      window.location.href =
        process.env.VUE_APP_BASE_API +
        "/api/v1/management/form-service-job-invite/export";
    };

    return {
      loading,
      pageParams,
      tableHeight,
      filter,
      dataList,
      statusFormat,
      loadData,
      clickCreate,
      deleteRow,
      clearFilter,
      downloadExcel,
    };
  },
});
